import { contact_info } from '@/utils/contact-info-dictionary'
import { ESTIMATED_CARD_MAIL_ARRIVAL_BUSINESS_DAYS } from '@/utils/constants'

export const ENGLISH_TRANSLATIONS = {
    pages: {
        marketing: {
            // Landing
            landing: {
                description: 'The first smart credit card that uses your home equity or car value to get you the lowest rates.',
                comingSoon: 'The Credit Card Reinvented',
                titleHtml: 'Our Lowest Rate<br/>5.9 - 9.9% APR<sup>1</sup>',
                subTitleHtml: 'A credit card to unlock home equity.<br/>$0 annual fee. $0 application fee.<br/>3 minutes to sign up.',
                cta: 'Join Waitlist',
                ctaNotInvited: 'Request Waitlist Invite',
                phoneNumberPlaceholder: 'Enter your phone number',
                phoneLegalText: 'By providing your number, you consent to receive text messages. Data rates may apply.',
                phoneLegalTextWithForgotHtml: "By providing your number, you consent to receive text messages. Data rates may apply. <a href='{url}'>Forget your invite code?</a>",
                dontHaveInviteCode: 'No Invite Code?',
                savings: {
                    titleHtml: 'Goodbye high interest rates. <br/> Hello savings.',
                    content:
                        "You shouldn't have to pay outrageous interest rates the months you don't pay your credit card bill in full. By using your car or home to secure your card, we get access to cheaper capital and we pass those savings on to you.",
                },
                intro: {
                    titleHtml: '🚀<br/>Save $1,450 <br/> Every Year',
                    subTitleHtml: 'The world’s first credit card that unlocks your home equity to reduce interest rates by up to 50% or more<sup>2</sup>',
                    savings: {
                        subTitleHtml: "Save over <strong class='text-white'>$1,450</strong> every year and over <strong class='text-white'>$120</strong> per month on interest by moving to Aven.",
                        how: '<strong>How is this calculated?</strong><br>This compares the cost of carrying an $11,000 monthly balance and making the minimum payments of 1% principal plus interest with a 20.24% APR Credit Card versus a 6.99% APR with Aven.',
                    },
                },
                steps: {
                    titleHtml: 'Get Smarter About Your Credit Card Debt',
                    p1Html: 'Put money back in your pocket by unlocking your home equity. Get historically low interest rates and stop wasting money on high interest credit cards.',
                    one: {
                        title: '1) Accept Your Invitation',
                        subTitle: 'Quickly join the waitlist by using your personal invitation code and then verifying your identity',
                    },
                    two: {
                        title: '2) Unlock Your Home Equity',
                        subTitle: "Once we launch, we'll use our patent-pending technology to unlock your home equity in minutes, not weeks – so you can get low interest rates!",
                    },
                    three: {
                        title: '3) Start Saving With Your Card',
                        subTitle: 'With your new Aven card, stop worrying about high interest charges or your “0% Intro Rate” expiring. Focus simply on savings. Forever.',
                    },
                },
                cardDetails: {
                    title: 'Money In Your Pocket Every Month',
                    apr: {
                        title: 'APR Range',
                        contentHtml: '5.9% – 9.9%<sup>1</sup>',
                    },
                    creditLine: {
                        title: 'Credit Lines',
                        content: '$5,000 – $35,000',
                    },
                    rewards: {
                        title: 'Rewards',
                        content: '1.5% Cash Back',
                    },
                    originationFee: {
                        title: 'Origination Fee',
                        content: '$0',
                    },
                    annualFee: {
                        title: 'Annual Fee',
                        content: '$0',
                    },
                    balanceTransferFee: {
                        title: 'Annual Balance Transfer',
                        content: 'Still $0 Fee',
                    },
                },
                how: {
                    titleHtml: 'As simple as a credit card. As cheap as home equity.',
                    content:
                        'The best of both worlds.<br/><br/>Aven is the first credit card that takes into account the equity you’ve built up in your home. <br/><br/>With our patented technology, we can unlock your home equity in minutes (not weeks) and help you start saving right away.',
                },
                faq: {
                    titleHtml: 'Have questions? <br /> We have answers.',
                    subTitle: 'We want to make sure you have all the answers before you start.',
                },
                allTheCardFeatures: {
                    title: 'All the credit card features you love',
                    featureOne: 'Free credit score tracking',
                    featureTwo: 'Auto line-increase reviews',
                    featureThree: 'US-based customer service',
                    featureFour: 'Simple web & mobile app',
                },
                balanceTransfer: {
                    titleHtml: 'Cut your high interest credit card debt in half - for $0 fees<sup>1</sup>',
                    subTitleHtml:
                        "You could potentially save over 50% in interest alone by transferring your existing high interest credit card debt to the low interest Aven Card<sup>2</sup></sup>  <br /><br />  No balance transfer fee.<br/>No origination fee.<br/>No introductory gimmicks.<br/><strong class='text-dark'>Just simple savings.</strong>",
                },
                reengage: {
                    titleHtml: 'Got an invite? <br/> Join the waitlist.',
                    subTitle: 'These are big decisions and we want to make sure you feel comfortable.',
                    cta: 'Join Limited Waitlist',
                    messageUs: 'Send Us a Text Message',
                    contactInfoHtml: `Send us a text us at <a class="text-underline text-muted" href='sms:${contact_info.sms}'>${contact_info.sms}</a> or email us at <a class="text-underline text-muted" href='mailto:${contact_info.support_email}'>${contact_info.support_email}</a>`,
                },
                support: {
                    titleHtml: 'Still have questions? <br/> Happy to chat.',
                },
                error: {
                    notValidPhoneNumber: 'Invalid US Number',
                },
                reasons: {
                    title: 'Loans can be good, when used for the right reason.',
                    consolidate: {
                        title: 'Consolidate Debt',
                        body: 'Simplify your finances by paying down loan balances or credit cards. Reduce the stress of multiple bills, at a lower interest rate than most credit cards.',
                    },
                    improvements: {
                        title: 'Improvements',
                        body: 'Improving your home can increase the value and comfort of your home. With a loan, you can pay for your kitchen makeover or a new roof over a longer time period, instead of one large bill.',
                    },
                    medical: {
                        title: 'Medical Expense',
                        body: 'A loan can help cover your unexpected hospital bills, dental work or other medical expenses for you or a loved one, when you need it most.',
                    },
                    relocation: {
                        title: 'Relocation',
                        body: 'Personal loans can help manage your moving costs by spreading out the payments over time, instead of one large bill all at once.',
                    },
                },
                bounceModal: {
                    title: 'What do you think about the Aven Card?',
                    thankYou: 'Thank you for your feedback!',
                    thankYouSubTitle: 'This really helps us improve and build a better product for you.',
                    option1: 'I don’t want to use my home equity',
                    option2: 'Rates not low enough',
                    option3: 'I want it!',
                },
            },

            // Landing
            landingReduceCardBills: {
                description: 'The first smart credit card that uses your home equity or car value to get you the lowest rates.',
                comingSoon: 'The Credit Card Reinvented',
                titleHtml: 'Reduce Credit<br />Card Bills',
                subTitleHtml: '5.9% to 9.9% APR<sup>1</sup><br />Credit Card Backed by Home Equity<br />No Annual or Balance Transfer Fees<br />Only 3 Minutes to Sign Up',
                cta: 'Join the Waitlist',
                phoneNumberPlaceholder: 'Enter your phone number',
                phoneLegalText: 'By providing your number, you consent to receive text messages. Data rates may apply.',
                dontHaveInviteCode: 'No Invite Code?',
                savings: {
                    titleHtml: 'Goodbye high interest rates. <br/> Hello savings.',
                    content:
                        "You shouldn't have to pay outrageous interest rates the months you don't pay your credit card bill in full. By using your car or home to secure your card, we get access to cheaper capital and we pass those savings on to you.",
                },
                intro: {
                    titleHtml: '💸<br/>Reduce your credit <br/>card bill every month',
                    subTitleHtml: 'The world’s first credit card that unlocks your home equity to reduce interest rates by up to 50%<sup>2</sup>',
                    savings: {
                        subTitleHtml:
                            "Save over <strong class='text-white'>$3,792</strong> in 3 years on interest payments and save <strong>35%</strong> or more on your monthly credit card bill by moving to Aven.",
                        how: '<strong>How is this calculated?</strong><br>This compares the cost of carrying an $11,000 monthly balance with a 20.24% APR Credit Card versus a 6.99% APR with Aven and paying 1% principal + fees per month. Your monthly bill goes from $294 to $174.08.',
                    },
                },
                steps: {
                    titleHtml: 'As simple as a credit card. As cheap as home equity.',
                    p1Html: 'Save money by unlocking your home equity.<br /><br />Get historically low interest rates and stop wasting money on high interest credit cards.',
                    one: {
                        title: '1) Join the Waitlist',
                        subTitle: 'Verify your identity with some personal information.',
                    },
                    two: {
                        title: '2) Unlock Your Home Equity',
                        subTitle: 'Use our patented technology to unlock your home equity in minutes.',
                    },
                    three: {
                        title: '3) Start Saving',
                        subTitle: 'With your new Aven card, stop worrying about high interest charges or your “0% Intro Rate” expiring.',
                    },
                },
                cardDetails: {
                    title: 'Cut your interest rate by up to 50%<sup>2</sup>',
                    apr: {
                        title: 'APR',
                        contentHtml: '5.9% to 9.9% APR<sup>1</sup>',
                    },
                    creditLine: {
                        title: 'Credit Limits',
                        content: '$5,000 – $35,000',
                    },
                    annualFee: {
                        title: 'Annual Fee',
                        content: '$0',
                    },
                    originationFee: {
                        title: 'Origination Fee',
                        content: '$0',
                    },
                    balanceTransferFee: {
                        title: 'Annual Balance Transfer',
                        content: 'Still $0',
                    },
                },
                how: {
                    titleHtml: 'How does it work? By easily unlocking your home equity.',
                    content:
                        "Aven is a credit card that takes into account the equity you've built up in your home.<br /><br />With our innovative technology, we can unlock your home equity in minutes (not weeks) and help you start saving right away.",
                },
                faq: {
                    titleHtml: 'Have questions? <br /> We have answers.',
                    subTitle: 'This card is not for everyone, so we want to make sure you have all the answers before you start. ',
                },
                allTheCardFeatures: {
                    title: 'All the credit card features you love',
                    featureOne: 'Free credit score tracking',
                    featureTwo: 'Auto line-increase reviews',
                    featureThree: 'US-based customer service',
                    featureFour: 'Simple web & mobile app',
                },
                noneOfGimmicks: {
                    title: 'With none of the gimmicks you hate.',
                    subTitleHtml:
                        'No high interest rates - save over 50% in interest alone by transferring your existing high interest credit card debt to the low interest Aven Card.<sup>2</sup><br /><br />No balance transfer fee.<br />No origination fee.<br />No introductory gimmicks.<br /><strong>Just simple savings.</strong>',
                },
                reengage: {
                    titleHtml: 'Got an invite? <br/> Join the waitlist.',
                    subTitle: 'These are big decisions and we want to make sure you feel comfortable.',
                    cta: 'Join Limited Waitlist',
                    messageUs: 'Send Us a Text Message',
                    contactInfoHtml: `You can also email us at <a href='mailto:${contact_info.support_email}'>${contact_info.support_email}</a> or text us at <a href='sms:${contact_info.sms}'>${contact_info.sms}</a>`,
                },
                support: {
                    titleHtml: 'Still have questions? <br/> Happy to chat.',
                },
                error: {
                    notValidPhoneNumber: 'Invalid US Number',
                },
                reasons: {
                    title: 'Loans can be good, when used for the right reason.',
                    consolidate: {
                        title: 'Consolidate Debt',
                        body: 'Simplify your finances by paying down loan balances or credit cards. Reduce the stress of multiple bills, at a lower interest rate than most credit cards.',
                    },
                    improvements: {
                        title: 'Improvements',
                        body: 'Improving your home can increase the value and comfort of your home. With a loan, you can pay for your kitchen makeover or a new roof over a longer time period, instead of one large bill.',
                    },
                    medical: {
                        title: 'Medical Expense',
                        body: 'A loan can help cover your unexpected hospital bills, dental work or other medical expenses for you or a loved one, when you need it most.',
                    },
                    relocation: {
                        title: 'Relocation',
                        body: 'Personal loans can help manage your moving costs by spreading out the payments over time, instead of one large bill all at once.',
                    },
                },
            },
            // About
            about: {
                sectionHeader: 'Company',
                title: 'Our goal is to save people money',
                subTitle: 'We are inventing new ways, both technologically and financially, to save people money.',
                principles: {
                    simple: {
                        title: 'Simple',
                        content:
                            'We believe that simple, clear, and honest are what make good companies great and long lasting. We want customers who genuinely want to use our products because it makes their lives better and easier.',
                    },
                    inventive: {
                        title: 'Inventive',
                        content:
                            'We believe that innovation comes from questioning and pushing what’s possible. Our singular goal is to invent new financial products that help you save money everyday.',
                    },
                    customerObsessed: {
                        title: 'Customer Obsessed',
                        content:
                            ' We believe that Aven is only as good as you think we are. We’re super focused on making sure our products are valuable to you. If we don’t think our product will help you - we’ll tell you today, then get to work on making sure it will tomorrow.',
                    },
                },
                leadership: 'Team',
                investors: 'Investors',
                bod: 'Board of Directors',
                dogs: 'Team Dogs',
            },

            // Support
            support: {
                sectionHeader: 'Support',
                title: 'Questions? <br/> We are here.',
                subTitle: 'Get answers to frequently asked questions, explore financial resources or chat with one of our support teammates.',
                faq: 'Frequently Asked Questions',
                chatCta: 'Start Chat',
                resources: 'Resources',
                creditFacts: '',
                chat: {
                    title: 'Chat With Us',
                    cta: 'Send Us a Text Message',
                    contentHtml: `The simplest way to get in touch with us is to just <a href='sms:${contact_info.sms}'>text us</a>. You can also email us at <a href='mailto:${contact_info.support_email}' />${contact_info.support_email}</a> or text us at <a href='sms:${contact_info.sms}' />${contact_info.sms}</a>`,
                },
                otherContacts: `You can also email us at <a href='mailto:${contact_info.support_email}'>${contact_info.support_email}</a> or text us at <a href='sms:${contact_info.sms}'>${contact_info.sms}</a>`,
            },

            // MobileApps
            mobileApps: {
                sectionHeader: 'Aven Card App',
                title: 'Get The App',
                subTitle: 'Download the Aven Card app to easily view and manage your card. Available for iPhone and Android.',
                iphone: 'iPhone',
                android: 'Android',
            },

            // Contact
            contact: {
                sectionHeader: 'Contact Us',
                title: 'Get In Touch',
                subTitle: `The easiest way to get in touch with us is to send us a message. You can also text us at ${contact_info.sms}.`,
                message: 'Send Us A Message',
                call: 'Call Us',
            },

            // Careers
            careers: {
                title: 'Join the team',
                subTitle: 'We’re looking for people who want to shape the Aven of tomorrow and change how people access affordable capital.',
                positionsTitle: 'Open positions',
                emailUsHtml: `Email us at <a href='mailto:${contact_info.job_email}'>${contact_info.job_email}</a> to get in touch. We would love to chat with you.`,
            },

            // Privacy
            privacy: {
                header: 'Privacy',
                title: 'Privacy Matters',
                subTitle: 'We value your privacy and want you to know how we collect, share, and use information.',
                dataUse: 'Data Use Policy',
                consumerPrivacy: 'Aven Consumer Privacy Notice',
                ccbPrivacy: 'Coastal Community Bank Privacy Notice',
            },

            // Important Disclosures
            importantDisclosures: {
                header: 'Disclosures',
                title: 'All the Nitty Gritty',
                subTitle: 'We want to make sure that you understand our products and have easy access to our disclosures.',
                earlyHelocDisclosure: 'HELOC Application Disclosure',
                eSignConsent: 'E-SIGN Consent',
                helocBooklet: 'CFPB HELOC Booklet',
                mobileBankingPolicy: 'Digital Services Agreement',
                charmHandbook: 'CFPB CHARM',
            },
        },
        survey: {
            thankYou: {
                title: 'Thank You',
                subTitle: 'We really appreciate your feedback',
                message: `Your feedback helps us improve our products, so that we can better support our customers.</br></br>If you have time, we'd love to understand more from you. Text ${contact_info.sms} to chat and we'll give you a $15 Amazon gift card.`,
                sendTextMsg: 'Send Us a Text Message',
            },
        },
        auth: {
            signIn: {
                title: 'Sign in to your account',
                placeholder: 'Enter phone number',
                cta: 'Sign In',
            },
            signInConfirm: {
                title: 'Confirmation code sent to',
                cta: 'Continue',
                placeholder: 'Confirmation code',
            },
            error: {
                notValidPhoneNumber: 'Not a valid phone number',
                codeNotFound: 'Invalid invite code',
                codeExpired: 'Your invite code has expired',
            },
        },
        identity: {
            title: 'Sorry, we could not verify your identity. Please double check the information below.',
        },
        identityQuestions: {
            title: 'In order to fully verify your identity, please answer the following questions.',
        },
        waitlist: {
            personalInfo: {
                title: 'Welcome {name} 👋  Just a little about you.',
                noInviteTitle: 'Welcome {name} 👋  Just a little about you.',
                dobPlaceholder: 'Date of Birth MM/DD/YYYY',
            },
            employer: {
                title: 'What is your source of income?',
                employedOption: 'Employed',
                otherOption: 'Self / Other',
                placeholder: 'Employer',
                otherPlaceholder: 'Source Of Income',
                jobTitlePlaceholder: 'Job Title',
                cta: 'Continue',
                legal: 'By clicking Continue, you authorize us to verify your employment and your income through a consumer reporting agency, such as Equifax.',
            },
            statedIncome: {
                title: 'What is your personal gross annual income? Income before taxes.',
                placeholder: 'Ex. $85,000',
                loading: 'Submitting...',
                cta: 'Continue',
                legal: 'Alimony, child support, or separate maintenance income need not be revealed if you do not wish to have it considered as a basis for repaying this obligation.',
            },
            statedUsage: {
                usageOne: 'Balance Transfer Other Debt',
                usageTwo: 'Home Improvement Project',
                usageThree: 'Emergency Fund',
                usageOther: 'Other',
                usageTitle: 'What do you think will be your initial use of the Aven Card?',
                amountTitle: 'What amount do you have in mind?',
                placeholderUsage: 'Describe your use case',
                placeholderAmount: 'How much do you need?',
            },
        },
        origination: {
            personalInfo: {
                title: 'Welcome {name} 👋  Just a little about you.',
                noInviteTitle: 'Welcome {name} 👋  Just a little about you.',
                dobPlaceholder: 'Date of Birth MM/DD/YYYY',
            },
            employer: {
                title: 'What is your source of income?',
                employedOption: 'Employed',
                otherOption: 'Self / Other',
                placeholder: 'Employer',
                otherPlaceholder: 'Source Of Income',
                jobTitlePlaceholder: 'Job Title',
                cta: 'Continue',
                legal: 'By clicking Continue, you authorize us to verify your employment and your income through a consumer reporting agency, such as Equifax.',
            },
            statedIncome: {
                title: 'What is your personal gross annual income? Income before taxes.',
                placeholder: 'Ex. $85,000',
                loading: 'Submitting...',
                cta: 'Continue',
                legal: 'Alimony, child support, or separate maintenance income need not be revealed if you do not wish to have it considered as a basis for repaying this obligation.',
            },
            preQualification: {
                title: 'Congratulations! We were able to confirm your pre-qualifed offer.',
                apr: 'Variable APR',
                creditLimit: 'Credit Limit',
                noAnnualFee: '✓ No Annual Fee',
                noBalanaceTransferFee: '✓ No Balance Transfer Fee',
                cta: 'Continue',
                step1title: '3 Quick Steps to Close. All online.',
                step1subTitle: 'Confirm your information, verify your income, and complete a 5-minute online session with our notary team – all online.',
                step2title: 'No Annual or Origination Fees',
                step2subTitle: 'Unlike most home equity products, there is no cost to get the card. We try to avoid as many fees as possible.',
                step3title: 'No Balance Transfer Fees',
                step3subTitle: 'Transfer your high-interest debt without any fees and save big on your monthly payments.',
                legalText1: 'This is calculated using the Aven minimum payment of 1% plus principal & interest.',
                legalText2:
                    'For more information, you can also read our <a href="/privacy" target="_blank" rel="noreferrer noopener">Privacy Policy</a> and <a href="/disclosures" target="_blank" rel="noreferrer noopener">Disclosures</a>.',
                legalText3:
                    'Final approval is subject to: (a) our ability to verify your income; (b) your monthly debt to income ratio meeting our credit requirements; and (c) your ability to give us a first or second mortgage lien on your residence.',
            },
            ssnVerification: {
                title: 'Check Offer',
                legalText: 'The Aven Visa Credit Card is issued by Coastal Community Bank, pursuant to a license from Visa U.S.A., Inc. Aven accounts are made by Coastal Community Bank, Member FDIC.',
            },
            setExpectations: {
                title: 'Great! Confirm your income so you can accept and sign the offer.',
                cta: 'Continue',
            },
            statedUsage: {
                usageOne: 'Home Improvement',
                usageOther: 'Other',
            },
            offerPreview: {
                titleHtml: "<span style='font-size:3rem'>🎉</span><br />Congrats, you qualified!",
                whyIsItDifferentIncome: 'We could not confirm your full stated income of {income}.',
                apr: 'Variable APR',
                creditLimit: 'Credit Limit',
                noAnnualFee: '✓ No Annual Fee',
                noBalanaceTransferFee: '✓ No Balance Transfer Fee',
                cta: 'Accept Offer',
                decline: 'Decline Offer',
                hardpull:
                    'By pressing Accept, you certify that the information you have provided in this application is correct and authorize Aven to obtain your consumer credit report. This hard credit pull may affect your credit score.',
                legalDocs: 'Please review the following documents.',
            },
            notaryScheduling: {
                schedulingTitle: 'Last Step! Pick a time for {names} to sign with our online notary.',
                signNowTitle: "Last step! Let's sign digitally with our online notary.",
                contentHeader: "WHAT'S NEXT",
                schedulingCreditScoreWarning: 'Scheduling may affect your credit score',
                signingCreditScoreWarning: 'Signing may affect your credit score',
                needId: 'You’ll need your ID',
                fiveTenMinutes: 'Signing takes 5-10 minutes, 100% online',
                translatorRequested: 'We will have a translator ready during your session.',
                button: {
                    immediateTimeSlot: 'Continue',
                    signNowWithANotary: 'Sign Now with a Notary',
                    immediateTimeSlotOnReschedule: 'Sign Now',
                    showOther: 'Show More Times',
                    showMoreToday: 'Show More Today',
                    showMoreTomorrow: 'Show More Tomorrow',
                    showMore: 'Show More',
                    showLessToday: 'Show Less Today',
                    showLessTomorrow: 'Show Less Tomorrow',
                    showNext5Days: 'Show Next 5 Days',
                    showLess: 'Show Less',
                },
                header: {
                    today: 'Today',
                    tomorrow: 'Tomorrow',
                    tomorrowWithDate: 'Tomorrow, {dateString}',
                },
                error: {
                    timeWasTakenError: 'Sorry, that time was taken. Please select another time.',
                    noUpcomingAppointmentsError: `Sorry, we're at capacity, but we're working hard to add more appointments! Please use your application link to try again tomorrow.`,
                    noUpcomingAppointmentsErrorWithReturnLink: `Sorry, we're at capacity, but we're working hard to add more appointments! Please use your application link to try again tomorrow: {returnLink}`,
                    immediateAppointmentUnavailableError: 'Sorry, an immediate appointment is no longer available. Please select a new time.',
                },
            },
            inPersonNotarizationScheduling: {
                title: 'Your county requires in-person signing. Schedule a time for {names} to sign with your local signing agent.',
                whatToKnow: 'What to know',
                witnessRequirement: '<b>You must bring an additional witness</b> who is over 18 with a valid photo ID.',
                whereSigningHappens: 'Your signing agent will meet you at <b>{address}</b>.',
                approximateTimes: {
                    morning: 'Morning',
                    afternoon: 'Afternoon',
                    evening: 'Evening',
                },
            },
            inPersonNotarizationScheduleStatus: {
                pendingTitle: '<b>Your signing appointment is requested for {formattedDate}</b>',
                confirmedTitle: '<b>Your signing appointment is scheduled for {formattedDate}</b>',
                completedTitle: ({ named }) => `<b>All set!</b> Your${named('isJoint') ? ' joint' : ''} signing appointment was completed on ${named('formattedDate')}`,
                description: 'Your signing will take place at {address}. We’ll send you appointment details at <span class="tw-font-bold">{phoneNumber}</span>',
                updateNumber: 'Update Number',
                beforeYourAppointment: 'Before your appointment',
                completeIdentityVerification: 'Complete your identity verification check before your signing appointment.',
                signingLocation: 'Signing location',
                requiredToSign: 'Required to sign',
                witnessRequiredItem: 'Your Witness (18+)',
                witnessRequirement: 'You must bring your own witness (18+ with valid photo ID).',
                you: 'you',
            },
            notaryConfirmation: {
                button: {
                    rescheduleSession: 'Reschedule Session',
                    rescheduleNow: 'Reschedule Now',
                },
                questionsButtonText: 'Questions? Schedule a call',
            },
            notaryFaq: {
                lienQuestion: 'Is there a lien on the home?',
                lienAnswer:
                    'Similar to other Home Equity Line of Credit products, a lien is placed on the home for the approved line size amount upon opening the account. This is standard for mortgage and HELOC products.',
                drawAndRepaymentQuestion: 'What is the draw and repayment period?',
                drawAndRepaymentAnswerOne:
                    "Aven does not require an initial draw for lines up to $100,000. Unlike most HELOCs, the Aven card doesn't have a conventional draw or repayment period. Instead Aven works just like most credit cards - with the ability to draw and repay at any time.",
                drawAndRepaymentAnswerTwo:
                    'For cards with lines above $100,000, if the draw in the first 90 days is less than $50,000, Aven may reduce the line size to the amount outstanding, plus $25,000.',
                closeAccountQuestion: 'How does someone close their Aven account?',
                closeAccountAnswer:
                    'If cardholders wish to close their account, a payoff letter should be sent to support@aven.com. Once balances are paid Aven will process liens and close the account. Payments using ACH on the app can delay this process significantly, therefore, please request a payoff to support@aven.com.',
            },
            personaVerification: {
                scheduledSession: `All set for your online notarization session on <b>{dateTime}</b>. For your security, please verify your identity online using a government issued ID.`,
                immediateSession: `Before joining your notary session, please verify your identity online using a government issued ID.`,
            },
            notaryPermissions: {
                title: 'Please allow camera and microphone access',
                promptTitle: 'Please grant camera and microphone access for your online notary session.',
                promptConfirmation: 'Great! A pop up should appear in a few seconds...',
                location: 'Please allow location access',
                noPermissions: "Looks like we can't access your camera or microphone. Check your permissions and refresh the page.",
                noDevices: "Looks like we can't find a camera and microphone on this device. Please use a video capable phone or computer.",
                thanks: 'Got it, thanks!',
            },
            hmda: {
                title: 'Last question! To ensure fair lending, we are legally required to ask the following. You can skip if you do not wish to provide.',
                skip: "<u>Skip, I Don't Wish To Provide</u>",
                loading: 'Saving...',
                skipCta: "Skip, I Don't Wish To Provide",
                cta: 'Continue',
                dontProvide: "I don't wish to provide",
                sex: {
                    placeholder: 'Sex',
                    male: 'Male',
                    female: 'Female',
                },
                ethnicity: {
                    placeholder: 'Ethnicity',
                    hispanic: {
                        placeholder: 'Hispanic/Latino',
                        mexican: 'Mexican',
                        puertoRican: 'Puerto Rican',
                        cuban: 'Cuban',
                        other: {
                            placeholder: 'Other Hispanic or Latino',
                            hint: 'Ex. Ecuadorian',
                        },
                    },
                    notHispanic: 'Not Hispanic/Latino',
                },
                race: {
                    placeholder: 'Race',
                    americanIndian: {
                        placeholder: 'American Indian or Alaska Native',
                        hint: 'What is your principal tribe?',
                    },
                    asian: {
                        placeholder: 'Asian',
                        asianIndian: 'Asian Indian',
                        chinese: 'Chinese',
                        filipino: 'Filipino',
                        japanese: 'Japanese',
                        korean: 'Korean',
                        vietnamese: 'Vietnamese',
                        other: {
                            placeholder: 'Other Asian',
                            hint: 'Ex. Taiwanese',
                        },
                    },
                    black: 'Black or African American',
                    hawaiian: {
                        placeholder: 'Native Hawaiian/Pacific Islander',
                        native: 'Native Hawaiian',
                        guamanian: 'Guamanian or Chamorro',
                        samoan: 'Samoan',
                        other: {
                            placeholder: 'Other Pacific Islander',
                            hint: 'Ex. Tongan',
                        },
                    },
                    white: 'White',
                },
            },
            coOwner: {
                withoutCoOwnerNameTitle: 'To know if someone else needs to sign the deed of trust, are you married or single?',
                consentSecondaryCardCreation: 'Add them as an authorized card holder and send a card (optional)',
                smsAuth: "We'll text your co-owner documents to sign after you have signed. Click continue to authorize sending them a text.",
                loading: 'Saving...',
                maritalStatus: {
                    placeholder: 'Marital Status',
                    married: 'Married',
                    single: 'Single',
                },
                // Below strings copied from pages.origination.coOwner in aven_frontend/aven/src/localization/en.ts
                yourSpouseString: 'Your Spouse',
                disputeCoOwnerTitle: 'Let us know what seems incorrect and add supporting documents.',
                disputeCoOwnerSpouseNote: 'Please note that spouses must sign, even if not on the title',
                disputeCoOwnerPlaceholder: 'What seems incorrect...',
                disputeCoOwnerSubtitle:
                    'Upload supporting county-recorded documents showing other signers are not on title. Examples include: quitclaim deed, interspousal transfer deed, or a recent grant or title documents.',
                updateModal: {
                    nameMisspelling: { name: 'Name misspelling', description: 'No worries, when you sign with our online notary, we can fix any spelling mistakes.' },
                    notOnTitle: {
                        name: 'They are not on title',
                        description: 'Title records show them as an owner of the property. You can proceed to sign while we review. Account opening may be delayed if another signer is required.',
                    },
                    onTheListTwice: { name: 'They are on the list twice', description: 'We will remove them as a signer. We will need to verify this mistake before account opening.' },
                    deceased: {
                        name: 'They are deceased',
                        description: 'Sorry to hear that. We will remove them as a signer. You will need to upload a death certificate prior to opening your account.',
                    },
                    widowed: {
                        name: "I'm widowed",
                        description: 'We will update the marital status on your application, you may need to upload a death certificate prior to opening your account.',
                    },
                    separated: {
                        name: "We're separated and I have a quitclaim deed",
                        description: `Since separation is treated as legally married, your spouse still needs to sign unless you have a recorded Quitclaim Deed. <b>You will need to provide a recorded Quitclaim Deed before activating your card.</b>`,
                    },
                    separatedNoQuitclaim: {
                        name: "We're separated but I don't have a quitclaim deed",
                        description:
                            "Since separation is treated as legally married, your spouse still needs to sign. If you'd like to remove your spouse as a required signer, you will need to submit a Quitclaim Deed to your county recorder.",
                    },
                    none: { name: 'None of the above', description: 'Unfortunately this person needs to sign in order for you to open your account.' },
                },
            },
            welcome: {
                titleMLS: 'Almost there!',
                titleStillNotarizing: 'Thank you for signing!',
                title: 'Welcome to Aven!',
                timeLine: `Your card typically arrives in ${ESTIMATED_CARD_MAIL_ARRIVAL_BUSINESS_DAYS} business days. To be ready when your card comes, download the Aven Card app.`,
                timeLineNoAppUpsell: `Your Aven Visa Card typically arrives in ${ESTIMATED_CARD_MAIL_ARRIVAL_BUSINESS_DAYS} business days.`,
                nextSteps: 'We’ll email your loan agreements & disclosures for your records. They’ll also be in the app after activation.',
                loading: 'Please wait...',
                pifMessage: 'Send this gift to a friend or neighbor, and we will give them {pifAmount} in credit. They may share part of it back with you!',
                pifTitle: 'You Got a {pifAmount} Super Gift',
                shareLinkMessage: 'Share the link. Tap to copy.',
                nativeCta: 'Share {pifAmount} – On Us',
                copyLink: 'Copy Share Link',
                pifLinkCopied: 'Link Copied',
                smsMessage: 'You’ve been invited to the magical Aven card experience. You are eligible for a {maxAmount} gift after joining via the link. {pifLink}', //sms max length per message is 160, current string max length is 150
                additionalSignersContent: `Any remaining signers should schedule their signing session using the link sent to the phone number provided.`,
                additionalSignersPrimaryContent: `Any remaining signers should schedule their signing session using the link sent to the phone number provided. You will be notified when all parties have signed, and the account has been opened.`,
                additionalSignersContentSecondary: `We will contact you if any documentation is needed before the account opening.`,
                fraudReview: `We are reviewing your application. We will reach out within 3 business days if we need any additional documentation. You will receive a final welcome email and text message if everything looks good.`,
                fraudReviewAdditional: `After that, the card typically arrives in ${ESTIMATED_CARD_MAIL_ARRIVAL_BUSINESS_DAYS} business days. We look forward to having you join Aven!`,
                mlsContingencyActive: `<p>
                    We detected that your home was listed for sale recently. To ensure the security of your home equity, we need to verify our lien filing before activation. <b>This typically takes 7 days.</b>
                    <br><br>
                    We will ship your card to you after this is complete. To be prepared for when your card arrives, you can <a class="text-primary text-underline mt-2" href="{appDownloadHref}">download the Aven app</a>.
                </p>`,
            },
            avenCare: {
                title: 'Built for peace of mind',
                kicker: 'Your Free Card Benefits',
                listItem1: {
                    title: 'Cancel anytime without fees',
                    content: 'You can cancel your card anytime to remove your home lien without fees.',
                },
                listItem2: {
                    title: 'Fraud charge protection',
                    content: "You won't pay for unauthorized charges if your card is lost or stolen– and you can pause it at anytime.",
                },
                listItem3: {
                    title: 'Spend confidently',
                    content: 'You will get a manageable credit line based on your verified income.',
                },
            },
            creditReportFrozen: {
                instantUnlockAvailable: {
                    title: 'Unfreeze {applicantFirstNamePossessive} credit report to schedule your notary appointment',
                    body: `We’re almost there! Unfreeze {applicantFirstNamePossessive} credit report to schedule a time to sign.`,
                    instantUnlockCta: 'Continue',
                    unlockAtExperianCta: 'Log in to Experian',
                    secondaryCta: 'Refresh',
                    secondaryCtaSubtext: 'Credit report unfrozen? Click the refresh button to try again.',
                    retriableError: `<b>Sorry, {applicantFirstNamePossessive} Experian credit report is still frozen</b><br/>Click the button below to retry unfreezing {applicantFirstNamePossessive} credit report.`,
                    nonRetriableError: `<b>Sorry, {applicantFirstNamePossessive} Experian credit report is still frozen</b><br/>Please use the button below to log in to Experian or contact Experian at 888-EXPERIAN (888-397-3742) for further assistance.`,
                },
            },
        },
        personalInfoMultiStep: {
            nameAndEmail: {
                title: 'Welcome 👋<br/>Tell us a little about yourself.',
            },
            address: {
                title: 'What is the address?',
            },
        },
        dashboard: {
            pulse: {
                title: 'You are on the list!',
                subTitle: "We'll start rolling out early this fall and will text updates as we get closer.",
                spotInLine: 'Spot In Line',
                prequal: 'Prequalified up to',
            },
        },
        shared: {
            unableToVerifyIdentity: {
                title: 'Sorry, it looks like we could not verify your identity',
                subTitle: 'If you think you entered some information incorrectly, please try again.',
                cta: 'Try Again',
            },
            unapproved: {
                header: 'Sorry, we were unable to approve your application at this time',
                p1: 'We appreciate your interest in Aven and your time in submitting your application. We have attached an Adverse Action Notice to help you understand potential reasons why you may not have been approved.',
                link: 'Download Document',
                contact: 'Contact Us',
            },
            thanks: {
                reasons: {
                    default: {
                        title: 'Unfortunately, there was an unexpected error',
                        subTitle:
                            "We are so sorry, something went wrong. <a href='{link}'>Start a new application</a> to try again. " +
                            "If you have any questions, please email us at <a href='{mailto}'>{displayEmail}</a>",
                        cta: 'Contact Us',
                    },
                    renter: {
                        title: 'Sorry, we are currently only available if you own a home',
                        subTitle: 'Our current product uses your home equity to help lower interest rates, so we are only accepting homeowners at this time.',
                    },
                    homeOwnership: {
                        title: 'Sorry, we are unable to verify your property ownership information',
                        subTitle: `If you think this is a mistake, please text us at <a href='sms:${contact_info.sms}' />${contact_info.sms}</a> and we can work with you to resolve.`,
                    },
                    homeIsTrustOrLLC: {
                        title: 'It looks like your home is in a trust or owned by an LLC',
                        subTitle:
                            'Unfortunately, we do not support homes in a trust or LLC at this time. We are working on adding support for this in the future. If you think this is a mistake, please give us a call, and we can work with you to resolve it.',
                    },
                    review: {
                        title: 'Thank you for submitting your information. It looks like we need to review it manually, and we will contact you within a few days.',
                        cta: 'Questions? Send Us A Message',
                        footer: "You can also email us at <a href='{mailto}'>{displayEmail}</a>",
                    },
                    authorization: {
                        title: "Please open the link we've sent to your phone and email.",
                        subTitle: "If you can't find the link, please email us at <a href='{mailto}'>{displayEmail}</a>",
                    },
                    identity: {
                        title: 'Too many identity failures',
                        subTitle: "Unfortunately, you've reached our limit for identity verification attempts. Please wait 48 hours to schedule your next notary session and try again.",
                    },
                    notaryRejection: {
                        title: 'Unfortunately, we were unable to notarize successfully',
                        subTitle: "For more info, email us at <a href='{mailto}'>{displayEmail}</a>",
                    },
                    noCameraAccess: {
                        title: 'We could not find a webcam to use. We sent a link to {applicantPhone} to try on your mobile phone.',
                        cta: 'Still not working?',
                    },
                    tooEarlyToAppointment: {
                        title: 'We are excited to talk too, but you are a little early',
                        subTitle: `Your appointment is scheduled for <b>{dateTime}</b>. Please come back a few minutes before or reschedule for a different time.`,
                        subTitleLinkText: 'reschedule for a different time',
                        cta: 'Reschedule',
                    },
                    rescheduling: {
                        subTitle: `Your appointment is scheduled for <b>{dateTime}</b>. Please come back a few minutes before or reschedule for a different time.`,
                        subTitleLinkText: 'reschedule for a different time',
                        cta: 'Reschedule',
                    },
                    priorCompletedAppointment: {
                        title: 'Need to reschedule your notary appointment?',
                        subTitle: 'If you were contacted by Aven to reschedule, tap the button below. If not, you are all finished and can close this tab.',
                        cta: 'Reschedule',
                    },
                    missedAppointment: {
                        title: 'It looks like we missed you.',
                        subTitleSignNow: 'You can go ahead and sign now or reschedule.',
                        subTitle: 'You can go ahead and reschedule your appointment.',
                        cta: 'Reschedule',
                    },
                    applicantNotReadyForSession: {
                        title: "It looks like you weren't ready for signing.",
                        subTitleSignNow: 'You can go ahead and sign now or reschedule.',
                        subTitle: 'You can go ahead and reschedule your appointment.',
                        cta: 'Reschedule',
                    },
                    ineligibleAppointment: {
                        title: 'Unfortunately, your application expired',
                        subTitle: 'Sorry, it looks like too much time has passed since you began your application. ' + 'Please visit our homepage and apply again.',
                        cta: 'Start A New Application',
                    },
                    unexpectedErrorOnNotarizationPage: {
                        title: 'Unable to notarize',
                        subTitle: "An unexpected error occurred. Please try entering the session again or text us at <a href='{smsTo}'>{displayPhone}</a>.",
                    },
                    declinedOffer: {
                        title: 'Sorry to see you go',
                        subTitle: "If you have any feedback or comments, we would love to hear from you. Please email us at <a href='{mailto}'>{displayEmail}</a>.",
                        cta: 'Send Feedback',
                    },
                    privateBrowsing: {
                        title: 'It looks like you are browsing in private mode',
                        subTitle:
                            "Unfortunately, our website does not work while in private mode. Please turn it off or switch to a different browser. If you have any questions, please email us at <a href='{mailto}'>{displayEmail}</a>",
                    },
                },
            },
        },
    },
    progressTitle: {
        waitlist: ['Identity Verification', 'Primary Usage', 'Income Verification'],
        origination: ['CHECK OFFERS', 'PREQUALIFIED', 'INCOME VERIFICATION'],
        coOwner: ['Co-Owner Identification', 'Co-Owner Identification'],
        coOwnerAuth: ['Identity Verification', 'Identity Verification'],
    },
    bankConnect: {
        loading: 'Loading...',
        loadingContent: 'We are currently processing your data to get you the best rate. This typically takes 30 seconds.',
        title: 'We need to connect your bank account to verify your income and enable quick cash advances.',
        paragraphOne: '<strong>Secure Income Verification</strong>',
        subParagraphOne: 'By connecting your bank account we can instantly verify your income, no pay stubs needed.',
        paragraphTwo: '<strong>Quick No Fee Cash Advances</strong>',
        subParagraphTwo: 'When we launch, if you’re approved, use your connected bank account to get cash when you need it, with $0 fees.',
        connect: 'Connect Bank',
        tryAgain: 'Unable to link bank account. Please try again, or try another bank account or other form of income verification.',
        insufficientIncome: 'Sorry, we were unable to verify your your personal income. Please use a different method or bank account.',
        cta: 'Connect bank',
        uploadPayStub: 'Upload Latest Pay Stub Instead',
    },
    selectIncomeVerificationMethod: {
        title: 'Final step! What is the easiest way for you to verify that income?',
    },
    payStubUpload: {
        title: 'Please add photos or PDFs of your latest pay stub',
        errorText: 'There was a problem uploading your pay stub. Please try again (note: refreshing the page may help).',
    },
    awaitingManualIncomeVerification: {
        title: 'We are currently reviewing your information. We will text you a link to finalize when we are done.',
    },
    offerPreview: {
        header: 'Hi 👋 Here are your offers! <br/> Select the best one for you.',
        revisedOfferHeader: 'Based on our calculations here is your updated offer.',
        loanOffer: 'Loan Offer',
        carSecured: '— CAR SECURED —',
        unsecured: '— UNSECURED —',
        loanAprAndTerm: '{0} Months  •  {1}% APR*',
        securedLoanContent: 'Using your car as collateral, we are able to significantly increase the amount and lower the APR.',
        unsecuredLoanContent: 'If you don’t have a car, or don’t want to use your car as collateral.',
        loading: 'Crunching Numbers...',
        saveloan: 'Saving...',
        legalOffer: 'The APRs above are based on having AutoPay turned on for your monthly payments. Final loan approval will requires income verification and vehicle information.',
        legal: "*APR (Annual Percentage Rate) is the cost of credit as a yearly rate and reflects both your interest rate and any origination fee. We don't charge an origination fee. The APRs above are based on having AutoPay turned on for your monthly payments. Final loan approval requires income verification and vehicle information.",
        faqTitle: 'Frequently Asked<br/>Questions',
        faq1q: 'How does a car secured loan work, and why is it a good option?',
        faq1aHtml:
            'By using your car as collateral, we get cheaper capital. We pass those savings on to you. So the benefit is that the monthly payments will be significantly lower than a traditional loan.',
        faq2q: 'Does it matter if I have a loan on my car?',
        faq2aHtml: 'We will work with you to refinance your car. Our goal is to lower your currently monthly payment, but at the very least we will match your current monthly payment. ',
    },
    bankSelect: {
        loading: 'Processing...',
        loadingContent: 'We are currently processing your data to get you the best rate. This typically takes 30 seconds.',
        header: 'Where should we deposit your money, if approved?',
        goHome: 'Go Home',
        tryAgain: 'Try Again',
        error: {
            productNotSupported: 'We apologize, your bank is not supported at this time. We will notify when support is available.Thanks.',
            invalidCredentials: 'Sorry, the credentials you provided is not valid. Please try again. thanks',
            somethingWentWrong: 'Sorry, we experienced some problems. Would you like to try again? if you need assistance, click the HELP button',
        },
    },
    conversation: {
        adBlockMessage: 'We understand your need for privacy. However, ad blockers will interfere with our application. Please disable it and refresh the page.',
        pleaseRefresh: 'Refresh the page if you have turned off the ad blocker',
        adBlockOff: "👍 Great! Let's get started.",
        helpWithLinkingBank: 'Having trouble linking your bank? Click HELP on the top right corner to get in touch with us.',
        noDepositoryAccount: 'Sorry, you do not have a checking or savings account with {bankName}. Please select another bank.',
        intro: 'Welcome! 👋 Just a little about you to instantly check for offers',
        whatIsYourAddress: 'Nice to meet you, {name}! What is your primary address?',
        foundAddress: 'Found it! Does this look right?',
        changeAddress: "Ok {name}, let's update your address.",
        mustLiveInCalifornia: 'I apologize. Currently, we can only accept California resident',
        linkBankMessage: 'Great! Just a few steps for final approval. Connect your bank to instantly verify your income.',
        selectBankMessage: 'In which account do you receive your primary income?',
        somethingWentWrong: `Sorry, there seems to be some network issues. Please try again. Thanks!`,
        verifyIdentity: 'Great, to verify your identity, what is the last 4 of your SSN?',
        successfullyQualified: 'Congrats, you qualified 🙌' + '<br/>Get the lowest APR by securing the loan with your car. Which option is right for you?',
        licensePlate: "What is your vehicle's license plate and state of registration?",
        doYouHaveInsurance: 'Is your car insured? We will ask for a photo later if you accept an offer.',
        checkSsn: 'Sorry, we could not verify your SSN. Please double check and try again. Thanks!',
        genericApplicantInfoError: 'Sorry, we could not verify your identity. Please double check the information below.',
        helocDeedOfTrust: 'Please review and sign the <br/> Deed of Trust',
    },
    bankReconnect: {
        title: 'Connect your bank',
        subTitle: 'In order to continue, we need to connect your bank.',
        cta: 'Reconnect bank',
        loading: 'Finding your account...',
    },
    bankSelectAccount: {
        title: 'What is your primary account?',
    },
    bankConnectionSuccess: {
        title: 'Great, we were able to reconnect your bank.',
        subTitle: 'Print your documents below for your records.',
    },
    components: {
        onboarding: {
            helpListItem: {
                title: 'Questions or thoughts?',
                altSubTitle: 'Thank you for your feedback. It really helps us build a better product for you. We will reach back shortly.',
            },
        },
        feedbackBox: {
            placeholder: 'Feedback or Comments?',
            cta: 'Send Message',
            successMessageTitle: 'Message Sent',
            successMessageBody: 'Thank you for your feedback. It really helps us build a better product for you. We will reach back shortly.',
        },
        navbarMarketing: {
            cta: 'Join the waitlist',
            signin: 'Sign In',
            careers: 'Careers',
            about: 'Company',
            home: 'Home',
            support: 'Support',
            forgotCode: 'Forgot Code',
            dashboard: 'Dashboard',
        },
        faq: {
            homeImpact: {
                question: 'If I miss a payment, does this impact my home?',
                answerHtml:
                    'No. Paying late or a few missed payments will not put your home at risk or in default. If you find you can’t make your payments, let us know and we can work with you on alternative payment arrangements. We don’t have high late fees and we won’t increase your interest rate for late or missed payments. As a lienholder we do have remedies available if you default, but foreclosure is our last option. Keep in mind that with our low APRs, your monthly bill could be much smaller than what you pay today.',
            },
            homeValue: {
                question: 'Does it matter how much my house is worth?',
                answerHtml:
                    'There is no minimum house value to apply. We will use the estimated value of your home, your outstanding mortgage balance, and your income to determine your credit limit.',
            },
            homeEquityLoan: {
                question: 'Why not a home equity line of credit instead?',
                answerHtml:
                    'Home equity lines of credit often come with high fees (usually over $1000), minimum advances, minimum credit limits (often at least $50,000), and take 4-8 weeks to get.<sup>3</sup> <br/> <br/> With Aven, you can get your card in just a few days in the mail, and there are no minimums so you can use what you want, when you want it, without any origination, annual or balance transfer fees.',
            },
            lostJob: {
                question: 'What happens if I lose my job?',
                answerHtml:
                    'We believe in long term relationships. For cases where our loyal customers have had a medical emergency or have lost their jobs, we have a special Aven-Hardships program to help.  We believe that helping you today  helps us tomorrow. Reach out to learn more!',
            },
            minCredit: {
                question: 'What is the minimum credit score?',
                answerHtml: '650 and above.',
            },
            howLong: {
                question: 'How long does it take to get my card?',
                answerHtml: `The application process and approval process can take as little as 15 minutes. Because this card is backed by your home, there is also a 3 day rescission period where you can cancel without any questions. Your card typically arrives within ${ESTIMATED_CARD_MAIL_ARRIVAL_BUSINESS_DAYS} days.`,
            },
            fees: {
                question: 'Are there any fees?',
                answerHtml:
                    'Unlike most HELOCs, we don’t charge an annual fee, recording fee, non-use fee, or origination fee. There is also no fee for your annual balance transfer. We do have a small fee for late payments ($29) and NSF payments ($15). Our ATM cash advance fee is the greater of $5 or 4% and our foreign transaction fee is 3%.',
            },
            fees99: {
                question: 'Are there any fees?',
                answerHtml:
                    'Unlike most HELOCs, we don’t charge an annual fee, recording fee or non-use fee, and our origination fee is only $99. There is also no fee for your annual balance transfer. We do have a small fee for foreign purchases (3%), cash advance for ATM withdrawals (the greater of $5 or 4%), late payments ($29) and returned payments ($15).',
            },
            criteria: {
                question: 'What are the criteria to qualify for this credit card?',
                answerHtml:
                    '1) Own and have equity in your home. <br/> 2) Have a credit score greater than 650. <br/> 3) Have an income that can support your monthly bill – we don’t want you to get into debt that you can’t afford.',
            },
        },
        formSelectAutocomplete: {
            noOptions: 'No results found for',
            startSearching: 'Start typing to search',
        },
        formFieldAddress: {
            placeholder: {
                completeAddress: 'Street Address, City, State',
                street: 'Street Address',
                unit: 'Apt #',
                city: 'City',
                state: 'State',
                postalCode: 'Zip',
            },
            enterAddressManually: 'Enter address manually',
            validation: {
                addressNotFound: 'Address Not Found. Please check your input.',
                CAResidentOnly: 'Must be a resident of California',
            },
            googleError: 'Could not find valid location, please try again',
        },
        formFieldSsn: {
            placeholder: 'Social Security Number',
            placeholderLast4: 'Last 4 of Social Security Number',
        },
        formFieldDob: {
            placeholder: 'Date of Birth',
            focusPlaceholder: 'DD/MM/YYYY',
        },
        formFieldPhone: {
            placeholder: 'Date of Birth',
            focusPlaceholder: 'DD/MM/YYYY',
        },
        applicationForm: {
            confirmOwnership: 'I confirm this is my primary residence',
            sectionHeader: {
                income: 'Monthly Income',
                identity: 'Identity Verification',
                personal: 'Personal Details',
            },
            legal: {
                statedIncome: 'Verified later by connecting your bank account.',
                doesNotEffectFicoHtml: "This will <span class='text-underline-2px'>NOT</span> affect your credit score.",
                softPullHtml:
                    "By continuing, you consent to our using a consumer reporting agency, like Experian, to make a soft credit inquiry and verify your employment and income. You also agree to our <a href='/docs/ESIGNConsent.pdf' target='_blank'>E-SIGN Consent</a>, <a href='/privacy' target='_blank'>Privacy Policy</a>, and <a href='/docs/TermsOfUse.pdf' target='_blank'>Terms of Use</a>.",
            },
            placeholder: {
                firstName: 'First Name',
                lastName: 'Last Name',
                statedIncome: 'Monthly Income (After Tax)',
                dateOfBirth: 'Date of Birth',
                applicantUpdateReason: 'Reason for update (15 character min)',
                dob: 'MM/DD/YYYY',
                email: 'Email Address',
            },
            validation: {
                invalidAddress: 'Invalid address',
            },
            defaultCta: 'Submit',
        },
        personalLoanBalanceTransferForm: {
            placeholder: {
                fullName: 'Full Name',
                billingAddressData: 'Billing Address',
                accountNumber: 'Account Number',
                bankName: 'Bank Name',
                transferAmount: 'Transfer Amount',
            },
            balanceInfo: '{amount} balance as of {date}',
            cta: 'Schedule Transfer',
            confirmOwnership: 'I certify that I own this account',
            error: {
                ownership: 'Please certify that you own this account.',
            },
        },
        balanceTransferModal: {
            error: {
                genericError:
                    'An error came up while processing your balance transfer request. Please refresh the page and try again. If this problem persists, please reach out to our support team for assistance with your balance transfer.',
                invalidAccountNumberFail:
                    'Sorry, that account number or bank name does not match our records. Please try again. If this problem persists, please reach out to our support team for assistance with your balance transfer.',
            },
            loadingTitle: 'Submitting...',
            intro: {
                title: 'Transfer From...',
                potentialSavings: 'Save {amount}%',
                footnote:
                    'Savings represent the difference in APR. APR savings are calculated based on the difference between the estimated APR for your current loan or credit card compared to your Aven Card. Note that your total credit limit is {creditLimit}.',
                otherAccountTitle: 'New Account',
            },
            iframe: {
                titleOther: 'New Account',
                ccOption: 'Credit Card',
                plOption: 'Personal Loan',
                footnote: "We won't process your balance transfer until after your 3 business-day cancellation period has expired. Note that your total credit limit is {creditLimit}.",
            },
            confirmation: {
                title: 'Transfer Scheduled',
                subTitle: "Although your transfer is scheduled, we won't start the transfer until your 3 business-day cancellation period has expired.",
                additionalAccountsCta: 'Transfer from other Accounts',
                doneCta: 'Done',
            },
        },
        emailVerificationHelpModal: {
            title: 'Help',
            subTitle: 'Verifying your work email allows us to verify employment, saving you the trouble of further income verification.',
            tryAgain: 'Try Again',
            goBack: 'Go Back',
        },
        updatePhoneNumberModal: {
            title: 'Please update your number',
            newPhoneNumber: 'New Mobile Number',
        },
        trustPilotWidget: {
            rating: '{score}/5',
            readReviews: '{numReviews} Reviews',
        },
    },
    global: {
        // Page not found
        pageNotFound: {
            sectionTitle: 'Page not found',
            title: "Sorry, looks like we sent you the wrong way. Let's fix that.",
            message: 'Head to the {homePageLink} to get started and learn more about the Aven Credit Card.',
        },
        loadingMessage: {
            loading: 'Loading...',
            verifying: 'Verifying...',
            saving: 'Saving...',
        },
        cta: {
            continue: 'Continue',
            next: 'Next',
        },
        errors: {
            genericRetry: 'Something went wrong. Please try again.',
            generic: 'Sorry, there was a problem with the network. Please refresh the page and try again. Thanks!',
            badRequest: 'An error came up while processing your information! Please review that the information you submitted is correct. You could also try refreshing the page. Thanks!',
            confirmAddress: 'Our system noticed an issue with your address. Dont worry, we fixed it. Please confirm the updated address and continue.',
        },
    },
    customValidationRules: {
        CAResidentOnly: 'Must be a resident of California',
        twilioCode: 'Code must be exactly 4 digits',
        incompleteAddress: 'Incomplete address',
        underAge: 'You must be over 18 years old',
        notValidPhoneNumber: 'Not a valid phone number',
        invalidUSPhoneNumber: 'Invalid US Phone number. Please try again.',
        phoneNumberCannotBeShared: 'All applicants must have a unique phone number, this number is shared with another applicant on this application. Please try again.',
        invalidHomeLocationDueToFloodZone: 'Sorry, we do not currently offer our card in special flood hazard areas',
        emailAlreadyExists: 'An account already exists for this email address',
        phoneNumberAlreadyExists: 'An account already exists for this phone number',
        duplicateSsnError: 'All applicants must have a unique SSN, please double check your inputs.',
        currencyTooSmall: 'Minimum allowed amount is {minAmount}',
        currencyTooLarge: 'Maximum allowed amount is {maxAmount}',
        genericInputError: 'Something went wrong. Please make sure the information you entered is correct and try again!',
        tooManyAttempts: 'Too many attempts! Please contact us to update your phone number.',
        applicantNameAlreadyExists: `{firstName} {lastName} is already a signer. Please try again with a different person.`,
    },
    validation: {
        alpha: 'May only contain alphabetic characters',
        alpha_num: 'May only contain alpha-numeric characters',
        alpha_dash: 'May contain alpha-numeric characters as well as dashes and underscores',
        alpha_spaces: 'May only contain alphabetic characters as well as spaces',
        between: 'Must be between {min} and {max}',
        confirmed: 'Confirmation does not match',
        digits: 'Must be numeric and exactly contain {length} digits',
        dimensions: 'Must be {width} pixels by {height} pixels',
        email: 'Must be a valid email',
        excluded: 'Is not a valid value',
        ext: 'Is not a valid file',
        image: 'Must be an image',
        invalidDate: 'Invalid date',
        integer: 'Must be an integer',
        length: 'Must be {length} characters long',
        max_value: 'Must be {max} or less',
        max: 'May not be greater than {length} characters',
        mimes: 'Must have a valid file type',
        min_value: 'Must be greater than {min}',
        is_not: 'Must be a valid value',
        min: 'Must be at least {length} characters',
        numeric: 'May only contain numeric characters',
        oneOf: 'Is not a valid value',
        regex: 'Format is invalid',
        required_if: 'This field is required',
        required: 'This field is required',
        size: 'Size must be less than {size}KB',
        fullname: 'Must include full name',
    },
    remoteNotarization: {
        preKBA: {
            title: 'Ok, final step! Our notary team will be joining you to sign the final documents.',
            audioVideoHint: 'You will be prompted to turn on your video and microphone.',
        },
        enterQueue: {
            title: 'Welcome To Aven Notary!',
            subTitle:
                'Your notary is finalizing your documents, and should join within a few minutes. Notary sessions are recorded for quality and training purposes. Please stay on this page while the documents are finalized.',
        },
        helocDeedOfTrust: {
            title: 'Deed Of Trust',
            signatureHint: 'Sign here with your finger',
        },
        pifInput: {
            title: 'You Got a ${pifAmount} Super Gift',
            desktopTitle: 'Invite a friend',
            numberInput: 'Enter Mobile Number',
            send: 'Send',
            share: 'Share ${pifAmount} – On Us',
            shareExplanation: 'Send this gift to a friend or neighbor, and we will give them ${pifAmount} in credit. They may share part of it back with you!',
            editMessageDisclaimer: 'Please edit the suggested message above, your friend will receive an invite link.',
            dncListDisclaimer: 'Please ensure your friend has either given consent or is not on the national Do Not Call Registry.',
            oklahomaDisclaimer: 'Not available for Oklahoma residents.',
            copyLink: 'Copy Share Link',
            linkCopied: 'Link Copied',
            linkCopiedSubtext: 'Paste it in a text or email',
            paymentExplanation:
                'Payment of the signing bonus to the recipient upon the activation of the recipient’s Aven Account. The bonus will be issued to the recipient in the form of statement credit after card activation and selecting an amount they wish to share. For more information, please see our <a href="https://www.aven.com/public/docs/PifTerms">PIF Terms and Conditions.</a>',
            thankYou: 'Thank you for referring your friend',
        },
        nameInput: {
            subtitleSelf: 'Please enter your name as it appears on your ID',
            subtitleOther: "Please enter {name}'s name as it appears on their ID",
            submit: 'Submit',
            firstNamePlaceHolder: 'First Name',
            lastNamePlaceHolder: 'Last Name',
        },
    },
    idVerification: {
        begin: {
            loading: 'Loading...',
            title: 'ID Verification',
            subTitle: 'Present an ID document to verify',
            cta: 'Verify ID',
            verifiedButtonText: 'Verified',
            failureText: 'Sorry, it seems that there was an issue validating your credentials. You have {numTriesRemaining} attempt{plural} remaining. Please try again in a few moments.',
            lastFailureText: 'Sorry, we are unable to validate your credentials. INSERT SOMETHING BETTER HERE.',
        },
    },
}
