import Vue from 'vue'
import VueRouter from 'vue-router'
import VueGtag from 'vue-gtag'
import VueGtm from '@gtm-support/vue2-gtm'
import { logger } from '@/utils/logger'
import { sharedPagePaths, sharedRoutes } from '@/routes/sharedRoutes'
import { appSessionStorage, sessionStorageKey } from '@/utils/storage'
import { notaryRoutes } from '@/routes/notaryRoutes'
import { originationRoutes } from '@/routes/originationRoutes'
import { checkPathsMatch, RouteOption } from '@/flow/flowUtility'
import { getNextPath, latestPath } from '@/flow/flowController'
import { currentContextForLogging } from '@/main'
import { isSafariPrivateBrowsing } from '@/utils/parseUserAgents'
import { notarizationBackGuardPagePaths } from '@/flow/notarizationFlow'
import { authRoutes } from '@/routes/authRoutes'
import { MiscThanksReasons } from '@/utils/thanksPageHelpers'
import { inspect } from '@/utils/inspect'
import { creditLimitIncreaseRoutes } from '@/routes/creditLimitIncreaseRoutes'
import { conferenceRoomRoutes } from '@/routes/conferenceRoomRoutes'
import { cliAndAprReductionAndBalanceSweepRoutes } from '@/routes/cliAndAprReductionAndBalanceSweepRoutes'
import { serviceLinkRoutes } from '@/routes/serviceLinkRoutes'

const DISABLE_NAVIGATION_GUARDS = false // disables redirects so components can be iterated on more quickly. don't forget to set back to false.
let NAVIGATED_ONCE = false // some components will redirect you manually. this gets set to true automatically in the beforeEach hook below.

Vue.use(VueRouter)

const routes = [
    ...authRoutes,
    ...sharedRoutes,
    ...notaryRoutes,
    ...serviceLinkRoutes,
    ...originationRoutes,
    ...creditLimitIncreaseRoutes,
    ...conferenceRoomRoutes,
    ...cliAndAprReductionAndBalanceSweepRoutes,
]

const backGuardPagePaths = [...notarizationBackGuardPagePaths]

const router = new VueRouter({
    mode: 'history',
    routes,
    scrollBehavior: function (to) {
        if (to.hash) {
            return { selector: to.hash }
        } else {
            return { x: 0, y: 0 }
        }
    },
})

if (['production', 'staging', 'uat', 'test'].includes(process.env.VUE_APP_NODE_ENV)) {
    Vue.use(
        VueGtag,
        {
            config: { id: process.env.VUE_APP_GOOGLE_ANALYTICS_TAG },
        },
        router
    )
}

if (process.env.VUE_APP_NODE_ENV === 'production' && process.env.VUE_APP_GOOGLE_TAG_MANAGER_ID) {
    Vue.use(VueGtm, {
        id: process.env.VUE_APP_GOOGLE_TAG_MANAGER_ID,
    })
}

router.onError((error) => {
    logger.info(`router error: ${inspect(error)}`)
    // See: https://blog.francium.tech/vue-lazy-routes-loading-chunk-failed-9ee407bbd58
    if (/Loading.*chunk.*failed./i.test(error.message)) {
        logger.info('Reloading page to fix stale chunk error')
        // @ts-ignore
        return window.location.reload(true)
    }

    throw error
})

router.afterEach(() => {
    try {
        for (const member in currentContextForLogging) {
            delete currentContextForLogging[member]
        }
    } catch (e) {
        logger.fatal(`error clearing current context for logging`, e)
    }
})

router.beforeEach((to, from, next) => {
    if (DISABLE_NAVIGATION_GUARDS) {
        if (NAVIGATED_ONCE) {
            return next(false)
        }
        NAVIGATED_ONCE = true
        return next()
    }

    logger.info(`notary routing from: ${from.path} to: ${to.path}`)
    window.previousPath = from.path

    // remove any modals if needed
    document.body?.classList?.remove('modal-open')
    document.getElementById('modal-backdrop')?.remove()

    let navigatedEarly

    // clear storage if clearStorageOnNavigation is present in storage
    navigatedEarly = clearStorageCheck(to)
    if (navigatedEarly) {
        return
    }

    // jwt token required for all paths that are not public
    navigatedEarly = authCheck(to, from, next)
    if (navigatedEarly) {
        return
    }

    // prevent users from navigating back on certain guard pages
    navigatedEarly = backGuardCheck(to, from, next)
    if (navigatedEarly) {
        return
    }

    return next()
})

export default router

const clearStorageCheck = (to) => {
    if (appSessionStorage.getItem(sessionStorageKey.clearStorageOnNavigation) !== 'true') {
        return false
    }

    if (process.env.VUE_APP_NODE_ENV !== 'production') {
        alert(
            'WARNING: Clear storage on navigation ignored on dev! Your storage would be cleared on prod! Be sure this is intended behavior.\nClearing the clearStorageOnNavigation key from storage now...'
        )
        appSessionStorage.removeItem(sessionStorageKey.clearStorageOnNavigation)
        return false
    }

    // clear storage will remove jwt token which will force user back to start page
    const startPagePath = appSessionStorage.getItem(sessionStorageKey.startPagePath) || '/'
    logger.info(`user wants to navigate when application process has ended. clearing storage and navigating to front page if necessary ${startPagePath}`)
    appSessionStorage.clear()

    if (to.matched.some((record) => record.meta.public)) {
        // preserve search params when navigating to non-public pages while forcing a reload to acquire a new session
        const searchParams = window.location.search
        window.location.href = to.path
        if (searchParams) {
            window.location.search = searchParams
        }
    } else {
        window.location.href = startPagePath
    }
    return true
}

const authCheck = (to, _, next) => {
    if (appSessionStorage.getItem(sessionStorageKey.jwtTokens) || to.matched.some((record) => record.meta.public)) {
        return false
    }

    if (isSafariPrivateBrowsing()) {
        next({ path: sharedPagePaths.THANKS, query: { reason: MiscThanksReasons.privateBrowsing } })
        return true
    }

    // force back to start page if there is no jwt
    const startPagePath = appSessionStorage.getItem(sessionStorageKey.startPagePath) || '/'
    logger.info(`no JWT found, next path is ${startPagePath}`)
    window.location.href = startPagePath
    return true
}

const backGuardCheck = (to, from, next) => {
    if (checkPathsMatch(latestPath, to.path)) {
        return false
    }

    for (const backGuardPagePath of backGuardPagePaths) {
        if (!checkPathsMatch(backGuardPagePath, from.path)) {
            continue
        }

        for (const option of [undefined, ...Object.values(RouteOption)]) {
            if (checkPathsMatch(from.path, getNextPath(to.path, option))) {
                if (process.env.VUE_APP_NODE_ENV !== 'development') {
                    logger.info(`navigation to ${to.path} from ${from.path} aborted by back guard`)
                    next(false)
                    return true
                } else {
                    alert('WARNING: Backguard ignored. This navigation would be aborted on prod and staging environments! Be sure this is intended behavior.')
                    return false
                }
            }
        }
        break
    }
    return false
}
