import { appSessionStorage, sessionStorageKey } from '@/utils/storage'
import { getNotarizationNextRouteWithCurrentPath } from '@/flow/notarizationFlow'
import { getNotaryNextRouteWithCurrentPath } from '@/flow/notaryFlow'
import { sharedPagePaths } from '@/routes/sharedRoutes'
import { RouteOption } from '@/flow/flowUtility'
import { logger } from '@/utils/logger'
import { getMobileNextRouteWithCurrentPath } from '@/flow/mobileFlow'
import { getServiceLinkNextRouteWithCurrentPath } from '@/flow/serviceLinkFlow'

export enum Flows {
    notarization = 'notarization',
    serviceLink = 'serviceLink',
    notaryAdmin = 'notaryAdmin',
    mobile = 'mobile',
}

export let latestPath = 'initializationPath'

export const getNextRoute = (pageInstance: { pageRoute: string }, routeOption?: RouteOption): string => {
    const nextPath = getNextPath(pageInstance.pageRoute, routeOption)
    latestPath = nextPath
    return nextPath
}

export const getNextPath = (currPath: string, routeOption?: RouteOption): string => {
    const currentFlow: Flows = appSessionStorage.getItem(sessionStorageKey.currentFlow) as Flows

    if (currentFlow === Flows.serviceLink) {
        const nextPath = getServiceLinkNextRouteWithCurrentPath(currPath, routeOption)
        return nextPath || sharedPagePaths.PAGE_NOT_FOUND
    }

    if (currentFlow === Flows.notarization) {
        const nextPath = getNotarizationNextRouteWithCurrentPath(currPath, routeOption)
        return nextPath || sharedPagePaths.PAGE_NOT_FOUND
    }

    if (currentFlow === Flows.notaryAdmin) {
        const nextPath = getNotaryNextRouteWithCurrentPath(currPath, routeOption)
        return nextPath || sharedPagePaths.PAGE_NOT_FOUND
    }

    if (currentFlow === Flows.mobile) {
        const nextPath = getMobileNextRouteWithCurrentPath(currPath, routeOption)
        return nextPath || sharedPagePaths.PAGE_NOT_FOUND
    }

    logger.info('flow not found, could not determine next page')
    return sharedPagePaths.PAGE_NOT_FOUND
}
