import { routeName as originationRouteName } from '@/routes/originationRoutes'
import { RouteConfig } from 'vue-router'

const serviceLinkPageNames = {
    SERVICE_LINK_SCHEDULING: 'serviceLinkScheduling',
    SERVICE_LINK_SCHEDULE_STATUS: 'serviceLinkScheduleStatus',
}

export const serviceLinkPagePaths = {
    SERVICE_LINK_SCHEDULING: `/${originationRouteName}/serviceLink/scheduling`,
    SERVICE_LINK_SCHEDULE_STATUS: `/${originationRouteName}/serviceLink/scheduleStatus`,
}

export const serviceLinkRoutes: RouteConfig[] = [
    {
        name: serviceLinkPageNames.SERVICE_LINK_SCHEDULING,
        path: serviceLinkPagePaths.SERVICE_LINK_SCHEDULING,
        component: () => import('@/pages/serviceLink/ServiceLinkScheduling.vue'),
    },
    {
        name: serviceLinkPageNames.SERVICE_LINK_SCHEDULE_STATUS,
        path: serviceLinkPagePaths.SERVICE_LINK_SCHEDULE_STATUS,
        component: () => import('@/pages/serviceLink/ServiceLinkScheduleStatus.vue'),
    },
]
